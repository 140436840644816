import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import "./styles/globals.css";
import "./styles/Home.module.css";
import HomePage from "./components/HomePage";
import NotFoundPage from "./components/NotFoundPage";
import LoadingPage from "./components/LoadingPage";
import CallbackPage from "./components/CallbackPage";

const REACT_APP_AUTHORITY_URL: string =
  process.env.REACT_APP_AUTHORITY_URL ?? "";

const REACT_APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? "";

// OIDC configuration
const oidcConfiguration = {
  authority: REACT_APP_AUTHORITY_URL,
  client_id: REACT_APP_CLIENT_ID,
  client_secret: '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',
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: "code",
  scope: "openid",
};

function App() {
  return (
    <Router>
      <AuthProvider {...oidcConfiguration}>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/authentication/callback" Component={CallbackPage} />
          <Route Component={NotFoundPage} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

//                     <Route path="/dashboard" element={SecureDashboard} />

export default App;
