import React, { ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  children: ReactNode;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, children }) => (
  condition ? <>{children}</> : null
);

export default ConditionalWrapper;
