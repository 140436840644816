import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const NotFoundPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const goToHome = () => {
    navigate('/'); // Navigate to homepage
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <button onClick={goToHome} style={{ marginTop: '20px' }}>
        Go Home
      </button>
    </div>
  );
};

export default NotFoundPage;
