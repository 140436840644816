
import React from 'react';
import '../styles/Loading.module.css'; // Assuming you have or will create this CSS module for styling
import { useAuth } from 'react-oidc-context';

const CallbackPage = () => {

  const auth = useAuth();

  return <>
      <h4>Auth Debug</h4>
      <p>{auth.error?.message}</p>
      <pre>{JSON.stringify(auth, null, 2)}</pre>  
  </>
};

export default CallbackPage;
